<template>
    <div class="data-content-data">
        <div style="width: 85%;margin: 0 auto;margin-top: 20px;">
            <div style="display: flex;justify-content: space-between;align-items: center;">
                <div style="display: flex;align-items: center;cursor: pointer;" @click="goBack">
                    <i style="font-weight: bold;" class="el-icon-arrow-left" />
                    <p style="color: var(--c-primary);margin-left: 4px;font-weight: bold;"> {{ entryInfo.category + ' - ' + entryInfo.subcategory }} </p>
                </div>
                <div class="custom-btn" @click="saveEntry">Save & Update</div>
            </div>


            <el-menu background-color="#f8f8f8" active-text-color="#D64643" :default-active="activeIndex" class="el-menu-style" mode="horizontal" @select="handleSelect">
                <el-menu-item  width="120" index="1">Entry Info</el-menu-item>
                <el-menu-item style="margin-left:30px" index="2">Grant of Liscence</el-menu-item>
                <el-menu-item v-if="entryInfo.entry_type == 'team'" style="margin-left:30px" index="3">Team Credits</el-menu-item>
            </el-menu>


            <div v-if="activeIndex == '1'">

                <div style="margin-top: 30px;line-height: 20px;">Entry Title*<br>
                   <span style="font-size: 12px;line-height: 15px;margin-top: 15px;"> Please use Title Case for the Entry Title, as it will appear on the certificate should you<br> receive an award.</span>
                </div>

                <div style="margin-top: 10px;">
                    <el-input maxlength="60" v-model="entryInfo.title" placeholder="Maximum 60 characters" style="width: 630px;"></el-input>
                </div>
                <div style="margin-top: 10px;line-height: 20px;">
                    Entry Description*
                </div>

                <div style="margin-top: 10px;">
                    <el-input  @input="(val)=> {handleInput(val,'entrydes' ,'')}" type="textarea" :rows="3" v-model="entryInfo.description" placeholder="Provide a brief introduction to the motivation, concept, philosophy, and creative process of vour work, not exceedine 150 words." style="width: 630px;"></el-input>
                </div>

                <div style="margin-top: 10px;line-height: 20px;">
                    Entry File<br>
                    <span style="font-size: 12px;line-height: 15px;margin-top: 15px;"> Please upload files in JPG or PDF format, not exceeding 5MB</span>
                </div>

                <div class="applyClass" style="position: relative;">

                    <input class="myFileUpload"  name="file" type="file"  accept=".jpg , .pdf" @change="getFile" />

                    <span class="addInput2">
                        Select
                    </span>

                </div>

                <div style="margin-top: 10px;margin-left: 0px;" v-if="file"> {{ file.name }}</div>
                <div @click="gotoDetail(entryInfo.file_url)"  style="margin-top: 10px;margin-left: 0px;cursor: pointer;color:cornflowerblue;" v-if="entryInfo.file_url"> {{ entryInfo.file_url }}</div>

                

                <div style="clear: both;"></div>

                <div style="margin-top: 10px;line-height: 20px;">URL
                </div>

                <div style="margin-top: 10px;">
                    <el-input v-model="entryInfo.video_url" placeholder="Provide video links for Video entries and URLs for Social Media or wehsite entries" style="width: 630px;"></el-input>
                </div>
                

                <!-- <el-form :model="entryInfo" :rules="rules" ref="entryForm" label-position="right" label-width="140px"
                style="margin-top: 20px;">
                    
                    <el-form-item label="Title" prop="title">
                    </el-form-item>
                    <el-form-item label="Category" prop="category">
                        <el-select v-model="entryInfo.category" placeholder="Please select" style="width: 400px;"
                            :disabled="true">
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Subcategory" prop="subcategory">
                        <el-select v-model="entryInfo.subcategory" placeholder="Please select" style="width: 400px;"
                            :disabled="true">
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Entry Type" prop="entry_type">
                        <el-radio v-model="entryInfo.entry_type" label="individual" :disabled="true">Individual</el-radio>
                        <el-radio v-model="entryInfo.entry_type" label="team" :disabled="true">Team</el-radio>
                    </el-form-item>
                    <el-form-item label="Tags" prop="tags">
                        <vue-tags-input style="font-size: 16px;" v-model="tag" :tags="tags" :max-tags="5"
                            :placeholder="tags.length > 0 ? '' : tagPlaceHolder"
                            @tags-changed="newTags => tags = newTags" />
                    </el-form-item>
                </el-form> -->
            </div>

            <div v-if="activeIndex == '2'">
               

                <div style="margin-top: 30px;line-height: 20px;"> 
                    Grant of License*
                </div>

                <div style="line-height: 20px;margin-top: 20px;word-break: normal;width: 630px;background-color: #E7E6E6;padding: 10px;">
                    I hereby grant World Student Publication Review (WSPR) a non-exclusive, royalty-free, worldwide, perpetual, and irrevocable license to edit, reproduce, distribute, publish, and/or display text, photos, audio, videos, email communications, or similar content ("the Works") submitted by me to WSPR. This license includes the right to incorporate the Works into collective works, create derivative works, and publish the Works online, on social media, and in any digital, audio, or print medium. I understand and agree that I will not receive any compensation for granting this license. I represent that I am the sole creator and owner of the Works, no other person or entity has any interest in them, and that the content of the Works is true and accurate.
                </div>


                <div style="margin-top: 20px;">
                    <el-radio v-model="license_radio" label="agree">I agree</el-radio>
                    <el-radio v-model="license_radio" label="noagree">I don't agree</el-radio>
                </div>

                <div style="margin-top: 20px;line-height: 20px;"> 
                    Statement of Originality and Liability Agreement*
                </div>

                <div style="line-height: 20px;margin-top: 20px;word-break: normal;width: 630px;background-color: #E7E6E6;padding: 10px;">
                    I hereby affirm that the works submitted by me to World Student Publication Review (WSPR) are my original creations, and I hold the complete intellectual property rights to these works. I also confirm that any third-party materials included in my submissions have been properly authorized for use. I understand and agree that I am solely responsible for any infringement of intellectual property rights, and WSPR shall not be held liable for any such infringements.
                </div>

                <div style="margin-top: 20px;">
                    <el-radio v-model="statement_radio" label="agree">I agree</el-radio>
                    <el-radio v-model="statement_radio" label="noagree">I don't agree</el-radio>
                </div>

            </div>

            <div v-if="activeIndex == '3'">

                <div style="margin-top: 30px;line-height: 20px;width: 630px;word-break: normal;"> 
                
                    Please describe each team member's responsibilities, contributions, and credits here. These
                    credits will accompany the certificates to ensure fair recognition for everyone. Use your
                    official name on our passport or ID card instead of "I". Maximum 50 words each.
                </div>


                <div style="margin-top: 20px;width: 630px;height: 400px;overflow-y: auto;">

                    <div v-for="item in members">
                        <div style="margin-top: 10px;">
                            <div > {{ item.show_name }}</div>
                        </div>

                        <div style="margin-top: 10px;">
                            <el-input @input="(val)=> {handleInput(val,'describe',item._id.$id)}" type="textarea" :rows="3"  v-model="item.show_describe" placeholder="" style="width: 630px;"></el-input>
                        </div>
                    </div>
                    
                </div>

            </div>
           
        </div>
    </div>
</template>
<script>
import {
    updateEntry,
    getEntry,
    getQNToken,
    updateEntryMemberDescribe
} from '../../api/index'
import VueTagsInput from "@johmun/vue-tags-input";
import * as qiniu from 'qiniu-js'

import { showLoading, hideLoading } from '../../utils/loadingutil'


import {
    getUserId
} from '../../utils/store'
export default ({
    name: 'index',
    components: {
        VueTagsInput
    },
    data() {
        return {
            tag: '',
            tags: [],
            entry_id: this.$route.query.entry_id,
            entryInfo: {

            },
            file:null,
            activeIndex:'1',
            license_radio: '',
            statement_radio: '',
            members:[],
            rules: {
                title: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                entry_type: [
                    { required: true, message: 'Please select', trigger: 'blur' }
                ],
                subcategory: [
                    { required: true, message: 'Please select', trigger: 'blur' }
                ],
                category: [
                    { required: true, message: 'Please select', trigger: 'blur' }
                ],
            },
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },

        handleSelect(key, keyPath) {
            console.log(key, keyPath);

            this.activeIndex = key
        },

        gotoDetail(url){
            window.open(url, '_blank')
        },
        fetchData() {
            getEntry(this.entry_id).then((res) => {
                this.entryInfo = res.data.data

                this.license_radio = this.entryInfo.license_radio ? this.entryInfo.license_radio : ''
                this.statement_radio = this.entryInfo.statement_radio ? this.entryInfo.statement_radio : ''

                this.members = this.entryInfo.members
                if (this.entryInfo.tags && this.entryInfo.tags != '') {
                    this.tags = []
                    let temp_tags = this.entryInfo.tags.split(',')
                    for (var i = 0; i < temp_tags.length; i++) {
                        var tag = { text: temp_tags[i] }
                        this.tags.push(tag)
                    }
                }
            })
        },
        saveEntry() {

            if (this.entryInfo.title == ''){
                this.$message.error("Please input entry title");
                return
            }

            if (this.entryInfo.description == ''){
                this.$message.error("Please input entry description");
                return
            }

            // if (this.entryInfo.video_url == ''){
            //     this.$message.error("Please input video link");
            //     return
            // }

            console.log(11111,this.license_radio)

            if (this.license_radio != 'agree'){
                this.$message.error("You must agree to the \"Grant of License\" and the \"Statement of Originality and Liability Agreement\" to submit your work.");
                return
            }

            if (this.statement_radio != 'agree'){
                this.$message.error("You must agree to the \"Grant of License\" and the \"Statement of Originality and Liability Agreement\" to submit your work.");
                return
            }


            if (this.file != null){
                this.submitTier()

                return
            }
            

            let params = {
                student_id: this.entryInfo.creater_id,
                id: this.entry_id,
                title: this.entryInfo.title,
                entry_type: this.entryInfo.entry_type,
                category: this.entryInfo.category,
                subcategory: this.entryInfo.subcategory,
                tags: this.entryInfo.tags,
                description:this.entryInfo.description,
                video_url:this.entryInfo.video_url,
                file_url:this.entryInfo.file_url,
                file_url:this.entryInfo.file_url,
                license_radio:this.license_radio,
                statement_radio:this.statement_radio


            }
            updateEntry(params).then((res) => {
                let result = res.data
                if (result.code == 0) {
                    this.$message.success("Save Successful");
                    // this.goBack()
                } else {
                    this.$message.error(result.msg);
                }
            })
        
    



            if (this.entryInfo.entry_type == 'team'){

                let data = []

                for (let i = 0; i < this.members.length; i++) {
                    let member_info = this.members[i]
                    let member_data = {}
                    member_data.member_id = member_info._id.$id
                    member_data.describe = member_info.show_describe

                    data.push(member_data)
                }

                console.log(1111,data)


                updateEntryMemberDescribe(data).then((res) => {
                    
                })


            }

            


            

            




            // let titleLength = this.entryInfo.title.length;
            // if (titleLength > 50) {
            //     this.$message.error("Entry title characters at maximum 50");
            //     return;
            // }
            // if (this.entryInfo.tags) {
            //     let temp_tags = this.entryInfo.tags.split(',')
            //     if (temp_tags.length > 5) {
            //         this.$message.error('Entry tags at maximum  5 tags')
            //         return
            //     }
            // }
            // this.entryInfo.tags = ''
            // if (this.tags.length > 0) {
            //     let length = this.tags.length
            //     for (var i = 0; i < length; i++) {
            //         var tag = this.tags[i].text
            //         this.entryInfo.tags = this.entryInfo.tags + tag
            //         if (i != (length - 1)) {
            //             this.entryInfo.tags = this.entryInfo.tags + ","
            //         }
            //     }
            // }
            // this.$refs.entryForm.validate((val) => {
            //     if (val) {
            //         let params = {
            //             student_id: this.entryInfo.creater_id,
            //             id: this.entry_id,
            //             title: this.entryInfo.title,
            //             entry_type: this.entryInfo.entry_type,
            //             category: this.entryInfo.category,
            //             subcategory: this.entryInfo.subcategory,
            //             tags: this.entryInfo.tags
            //         }
            //         updateEntry(params).then((res) => {
            //             let result = res.data
            //             if (result.code == 0) {
            //                 this.$message.success("Save Successful");
            //                 this.goBack()
            //             } else {
            //                 this.$message.error(result.msg);
            //             }
            //         })
            //     }
            // })
        },
        getFile (e) {
            const file = e.target.files[0]

            console.log(file)
            if (file.size / 1024 / 1024 > 5) {
                this.$message({
                message: 'The file size cannot exceed 5 MB. Please upload again.',
                type: 'error'
                })
                this.file = null
                return false
            } else {
                this.file = file
            }

            console.log(123,this.file)

            // this.videoName = this.file.name
            // this.uploadFile()
        },
        async getQiniuToken () {
            return new Promise((resolve) => {
                getQNToken().then((res) => {
                resolve(res)
                })
            })
        },
        submitTier(){


            showLoading()

            this.uploadVideoFile()


        },
        async uploadVideoFile () {
            console.log(1232131213)

            const result = await this.getQiniuToken()

            console.log(8888, result)

            if (result.data.code === 0) {
                console.log(1222)

                this.proVisible = true
                this.process = 1

                const token = result.data.data
                this.observable = qiniu.upload(
                this.file,
                new Date().getTime() + this.file.name,
                token
                )
                const observer = {
                complete: (res) => {
                    var url = 'https://ap.learningfirst.tech/' + res.key

                    
                    // console.log(111111222,url)

                    // resolve(url)
                    this.submitTierInfo(url)

                },
                next: (res) => {
                    // this.process = parseFloat(res.total.percent.toFixed(1))
                }
                }
                this.observable.subscribe(observer)
            } else {
            }
        },
            
        submitTierInfo(url){
   
            let params = {
                        student_id: this.entryInfo.creater_id,
                        id: this.entry_id,
                        title: this.entryInfo.title,
                        entry_type: this.entryInfo.entry_type,
                        category: this.entryInfo.category,
                        subcategory: this.entryInfo.subcategory,
                        tags: this.entryInfo.tags,
                        description:this.entryInfo.description,
                        video_url:this.entryInfo.video_url,
                        file_url:url,
                        license_radio:this.license_radio,
                        statement_radio:this.statement_radio
                    }
                    updateEntry(params).then((res) => {
                        let result = res.data
                        hideLoading()

                        if (result.code == 0) {
                            this.$message.success("Save Successful");
                            // this.goBack()
                        } else {
                            this.$message.error(result.msg);
                        }
                    })
        },

        handleInput(value, key ,id) {
            let index = 0
            const selfValue = value.split(' ')
            let count = 0
            let charCode = -1
            let titleLength = 150

            if (key == 'describe'){
                titleLength = 50
            }
            
            console.log(1111,selfValue.length , titleLength)
            
            let str = ''

            if (selfValue.length > titleLength ){
                str = value

                if (key == 'describe'){
                    
                    for (let i = 0; i < this.members.length; i++) {
                        let member_info = this.members[i]
                        if (member_info._id.$id == id){
                            member_info.show_describe = selfValue.slice(0, titleLength).join(' ');
                        }
                    }

                }else{
                    this.entryInfo.description = selfValue.slice(0, titleLength).join(' ');
                }


            }

        }



    }
})
</script>

<style scoped lang="scss">
.custom-btn {
    border-radius: 5px;
    padding: 6px 10px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    cursor: pointer;
    text-align: center;
    background: var(--c-primary);
    width: fit-content;
}
.el-menu-style{
    background: #f8f8f8;
}
.applyClass{
  // float: left;
  margin-left: 0px;
  margin-top: 10px;
  width: 160px;
  height: 36px;
  // line-height: 36px;
  font-size: 13px;
  text-align: center;
  color: white;
  // background-color: red;
}
.addInput2{
//   font-family: "DDINAlternateRegular";
  font-weight: 600;
  color: white;
  background-color: #D64643;
  // border: 1.5px solid #0E4890;
  height: 36px;
  width: 160px;
  line-height: 36px;
  text-align: center;
  // background: white;
  // border-radius: 5px;
  // padding: 4px 12px;
  cursor: pointer;
  margin-top: 0px;
  display: inline-block;
}
.myFileUpload
{
    position: absolute;
    display: block;
    width: 100px;
    height: 40px;
    opacity: 0;
    // top: 10px;
}
</style>